import React from 'react'
import SearchBar from 'material-ui-search-bar'
import IconClose from '@material-ui/icons/Close'
import withStyles from '@material-ui/core/styles/withStyles'

const WrapperIconClose = withStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})(({ classes, children, ...rest }) => (
  <div {...rest} className={classes.root}>
    {children}
  </div>
))

const CustomSearchbar = withStyles({
  root: {
    '& > div': {
      order: 1,
    },
  },
})(({ classes, children, searchTerm, runSearch, changePopper, ...rest }) => (
  <SearchBar
    className={classes.root}
    placeholder="Pesquise no InforMED"
    value={searchTerm}
    closeIcon={
      <WrapperIconClose onClick={() => runSearch('')}>
        <IconClose style={{ color: 'rgb(158, 158, 158)' }} />
      </WrapperIconClose>
    }
    onChange={(newValue) => {
      changePopper()
      runSearch(newValue)
    }}
  />
))

export default CustomSearchbar
